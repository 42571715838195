export const AppPath = {
  login(): string {
    return "/login";
  },
  signup(): string {
    return "/signup";
  },
  onboarding(): string {
    return "/onboarding";
  },
  postOnboarding(): string {
    return "/postonboarding";
  },
  newTeam(): string {
    return `/newTeam`;
  },

  team(teamId: string): string {
    return `/teams/${teamId!}`;
  },

  portals(teamId: string): string {
    return `${this.team(teamId)}/portals`;
  },
  portal(teamId: string, portalId: string): string {
    return `${this.portals(teamId)}/${portalId}/version`;
  },
  portalVersion(
    teamId: string,
    portalId: string,
    versionId: string,
    editPortal?: boolean
  ): string {
    const baseUrl = `${this.portal(teamId, portalId)}/${versionId}`;
    const queryParam = editPortal ? "?showPortalModal=true" : "";
    return `${baseUrl}${queryParam}`;
  },

  logs(teamId: string): string {
    return `${this.team(teamId)}/logs`;
  },
  log(teamId: string, logId: string): string {
    return `${this.team(teamId)}/logs/${logId}`;
  },

  workflows(teamId: string): string {
    return `${this.team(teamId)}/workflows`;
  },
  workflow(teamId: string, workflowId: string): string {
    return `${this.workflows(teamId)}/${workflowId}`;
  },

  settings(teamId: string): string {
    return `${this.team(teamId)}/settings`;
  },
  aiProviders(teamId: string): string {
    return `${this.settings(teamId)}/providers`;
  },
  billing(teamId: string): string {
    return `${this.settings(teamId)}/billing`;
  },
  members(teamId: string): string {
    return `${this.settings(teamId)}/members`;
  },
  variables(teamId: string): string {
    return `${this.settings(teamId)}/variables`;
  },
  teamProfile(teamId: string): string {
    return `${this.settings(teamId)}/team-profile`;
  },
  testCenter(
    teamId: string,
    portalId?: string,
    testId?: string,
    versions?: string[]
  ): string {
    let url = `${this.team(encodeURIComponent(teamId))}/test-center`;

    const params: string[] = [];

    if (portalId) {
      params.push(`portal=${encodeURIComponent(portalId)}`);
    }

    if (testId) {
      params.push(`testId=${encodeURIComponent(testId)}`);
    }

    if (versions && versions.length > 0) {
      params.push(`versions=${versions.map(encodeURIComponent).join(",")}`);
    }

    if (params.length > 0) {
      url += `?${params.join("&")}`;
    }

    return url;
  },
};
