export const ExternalPath = {
  workflowDocs(): string {
    return "https://docs.easybeam.ai/features/workflows";
  },
  workflowInfo(): string {
    return "https://easybeam.ai/workflows";
  },
  docs(): string {
    return "https://docs.easybeam.ai";
  },
  api(): string {
    return "https://docs.easybeam.ai/api";
  },
  tos(): string {
    return "https://easybeam.ai/privacy";
  },
  pp(): string {
    return "https://easybeam.ai/terms";
  },
  portalDocs(): string {
    return "https://docs.easybeam.ai/features/portals";
  },
};
