import React, { useEffect, useState } from "react";
import CommonHeader from "../Common/CommonHeader";
import CommonContainer from "../Common/CommonContainer";
import { useNavigate, useParams } from "react-router-dom";
import { CommonSpinner } from "../Common/CommonLoading";
import { useWorkflowService } from "../../contexts/WorkflowContext";
import { Workflow } from "../../models/Workflow";
import { formatDate } from "../../utils/DateUtils";
import { AppPath } from "../../models/AppPath";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { WorkflowStatusBadge } from "./WorkflowStatusBadge";

interface WorkflowsProps {}

const Workflows: React.FC<WorkflowsProps> = () => {
  const { teamId } = useParams<{ teamId: string }>();

  const workflowService = useWorkflowService();
  const navigate = useNavigate();

  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [loading, setLoading] = useState(true);
  const [createWorkflowState, setCreateWorkflowState] =
    useState<AnimationState>("ready");
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadData = async () => {
      const fetchedWorkflows = await workflowService.workflowRepo.getList(
        workflowService.workflowPath(teamId!),
        { name: "modifiedAt", descending: true }
      );
      if (fetchedWorkflows) {
        setWorkflows(fetchedWorkflows);
      }
      setLoading(false);
    };

    loadData();
  }, [workflowService, teamId]);

  const handleRowClick = (workflow: Workflow) => {
    navigate(AppPath.workflow(teamId!, workflow.id!));
  };

  const handleCreateNew = async () => {
    setCreateWorkflowState("loading");
    try {
      const newWorkflow = await workflowService.createWorkflow(teamId!);
      navigate(AppPath.workflow(teamId!, newWorkflow.id!));
    } catch (e) {
      setError(
        e instanceof Error
          ? e.message
          : "Failed to create portal. Please try again."
      );
      setLoading(false); // Ensure loading is set to false if there's an error
      setCreateWorkflowState("error");
    }
  };

  return (
    <CommonContainer>
      <CommonHeader
        title="Workflows"
        subtitle="Chain together multiple portals, inject them with data, and take actions. You can finally turn your dreams into beams."
        sections={[{ name: "Workflows", link: "" }]}
        teamId={teamId!}
        actions={[
          <AnimatedButton
            title="Create new Workflow"
            onClick={handleCreateNew}
            buttonState={createWorkflowState}
            setButtonState={setCreateWorkflowState}
            style="action"
            key="createWorkflow"
            error={error}
            setError={setError}
          />,
        ]}
      />
      <div>
        <table className="border rounded-lg border-gray-500 w-full overflow-hidden bg-gray-200">
          <thead className="border border-gray-200">
            <tr>
              <Header title="Name" />
              <Header title="Edited" />
              <Header title="Score" />
              <Header title="Status" />
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr className="bg-gray-0 hover:bg-gray-100">
                <td className="border-b border-l border-gray-200 p-3 font-medium text-sm">
                  <CommonSpinner />
                </td>
                <td className="border-b border-l border-gray-200 p-3 font-medium text-sm">
                  <CommonSpinner />
                </td>
                <td className="border-b border-l border-gray-200 p-3 font-medium text-sm">
                  <CommonSpinner />
                </td>
                <td className="border-b border-l border-gray-200 p-3 font-medium text-sm">
                  <CommonSpinner />
                </td>
              </tr>
            ) : (
              workflows.map((workflow) => (
                <tr
                  className="bg-gray-0 hover:bg-gray-100"
                  onClick={() => handleRowClick(workflow)}
                  key={workflow.id}
                >
                  <td className="border-b border-l border-gray-200 p-3 font-medium text-sm">
                    {workflow.name}
                  </td>
                  <td className="border-b p-3 text-sm text-gray-500 font-medium">
                    {formatDate(workflow.modifiedAt)}
                  </td>
                  <td className="border-b border-gray-200 p-3 font-medium text-sm">
                    {workflow.score ?? "..."}
                  </td>
                  <td className="border-b border-gray-200 p-3 font-medium text-sm">
                    <div className="flex">
                      <WorkflowStatusBadge workflow={workflow} />
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </CommonContainer>
  );
};

export default Workflows;

const Header: React.FC<{ title: string }> = ({ title }) => {
  return (
    <th className="p-3 bg-gray-50 text-left p4 text-xs font-medium text-gray-500 uppercase tracking-widest">
      {title}
    </th>
  );
};
