import React, { useEffect, useRef, useState } from "react";
import { useTestService } from "../../contexts/TestContext";
import { TestRun } from "../../models/TestRun";
import Icon from "../Icon";
import { CommonSpinner } from "../Common/CommonLoading";
import { Portal } from "../../models/Portal";
import { usePortalService } from "../../contexts/PortalContext";
import { useNavigate } from "react-router-dom";
import { formatDate, formatTime } from "../../utils/DateUtils";
import { AppPath } from "../../models/AppPath";
import { PortalTestParameters } from "../../models/PortalTest";
import { StatusBadge } from "./StatusBadge";
import { AIProviderLogo } from "../Portals/Portal Detail/AIProvider/AiProviderLogo";

interface TestRunDetailProps {
  closed: () => void;
  testRunId: string;
  teamId: string;
  portalId: string;
}

export const TestRunDetail: React.FC<TestRunDetailProps> = ({
  closed,
  testRunId,
  teamId,
  portalId,
}) => {
  const testService = useTestService();
  const portalService = usePortalService();
  const navigate = useNavigate();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const [testRun, setTestRun] = useState<TestRun>();
  const [portal, setPortal] = useState<Portal>();

  const [parameterListOpen, setParameterListOpen] = useState(false);

  const [error, setError] = useState("");

  const observationRef = useRef<() => void>();

  const handleClose = () => {
    observationRef.current?.();
    closed();
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        handleClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    const loadData = async () => {
      try {
        observationRef.current = testService.testRunRepo.observe(
          testService.testRunPath(teamId, portalId),
          testRunId,
          async (fetchedTestRun) => {
            setTestRun(fetchedTestRun);
          }
        );
        const portal = await portalService.portalRepo.get(
          portalService.portalPath(teamId),
          portalId
        );
        if (!portal) {
          return;
        }
        setPortal(portal);
      } catch (e) {
        setError(e instanceof Error ? e.message : "Something went wrong");
      }
    };
    loadData();
  }, [testService]);

  const handleSelectedTest = () => {
    if (!testRun) {
      return;
    }
    navigate(
      AppPath.testCenter(teamId, portalId, testRun.testId, [
        testRun.versionId ?? "",
      ])
    );
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50 overflow-y-scroll"
      onClick={handleClose}
    >
      <div
        className="bg-white max-h-[90%] rounded-lg flex flex-col shadow-lg w-[544px] relative overflow-y-auto"
        onClick={(e) => e.stopPropagation()} // Prevent click from propagating to the background
      >
        <div className="flex justify-end">
          <button
            className="text-gray-200 hover:text-gray-300 pt-8 pr-6"
            onClick={handleClose}
          >
            <Icon type="x" className="size-5" />
          </button>
        </div>
        {!testRun && (
          <div className="items-center justify-center flex pb-4">
            <CommonSpinner />
          </div>
        )}
        {error && (
          <div className="flex flex-col w-full overflow-y-auto p-6 gap-6">
            <div className="font-gooper text-lg">{error}</div>
          </div>
        )}
        {testRun && (
          <div className="flex flex-col w-full overflow-y-auto p-6 gap-4">
            <div className="flex justify-between items-center">
              <div className="flex justify-between text-xs text-gray-500 gap-2">
                <span> Created</span>
                <span>{formatDate(testRun.createdAt ?? undefined)}</span>
                <span>{formatTime(testRun.createdAt ?? undefined)}</span>
              </div>
              <StatusBadge status={testRun.status} />
            </div>
            <div className="text-gray-700 text-lg font-gooper text-center cursor-pointer">
              {`${testRun.testName}`}
            </div>
            <div className="flex items-center justify-between">
              <div
                className="text-blue-400 underline text-xs cursor-pointer hover:text-blue-700"
                onClick={() => navigate(AppPath.portal(teamId, portalId))}
              >
                {`${portal?.name}`}
              </div>
              <div
                className="text-blue-400 underline text-xs cursor-pointer hover:text-blue-700"
                onClick={() =>
                  navigate(
                    AppPath.portalVersion(teamId, portalId, testRun.versionId!)
                  )
                }
              >
                {`${testRun.versionName}`}
              </div>
            </div>
            <ParameterList
              usedParameters={testRun.parameterValues}
              isOpen={parameterListOpen}
              setIsOpen={setParameterListOpen}
              selectedTest={handleSelectedTest}
            />
            <div className="flex flex-col rounded-lg border border-gray-200 p-4 gap-2">
              <div className="text-gray-700 font-medium text-sm font-gooper">
                {`Prompt`}
              </div>
              <div className="text-gray-700 text-sm">
                {testRun.filledPrompt}
              </div>
            </div>
            <div className="flex flex-row justify-between items-center rounded-lg border border-gray-200 px-4 py-2 gap-2">
              <div className="flex flex-row gap-2">
                <div className="text-gray-700 font-medium text-sm font-gooper">
                  {`Provider`}
                </div>
                <button
                  onClick={() =>
                    navigate(
                      AppPath.portalVersion(
                        teamId,
                        portalId,
                        testRun.versionId!
                      )
                    )
                  }
                  className="text-blue-400 text-xs underline text hover:text-blue-700"
                >
                  View config
                </button>
              </div>
              <div className="flex px-4 py-2.5 rounded-lg bg-gray-100 h-10">
                <AIProviderLogo
                  providerId={testRun.configId}
                  className="text-gray-400 h-full w-20"
                />
              </div>
            </div>
            <div className="flex flex-col rounded-lg bg-gray-50 p-4 gap-2">
              <div className="text-gray-700 text-sm font-medium font-gooper">
                {`Output`}
              </div>
              <div className="text-gray-900 font-medium text-sm">
                {testRun.result}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between rounded-lg border border-gray-200 p-4 gap-2">
              <div className="flex flex-row gap-2">
                <span className="text-gray-500 text-sm">{`Tokens Used`}</span>
                <span className="text-gray-700 text-sm font-medium font-gooper">
                  {`${testRun.tokensUsed}`}
                </span>
              </div>
              <div className="flex flex-row gap-2">
                <span className="text-gray-500 text-sm">{`Latency`}</span>
                <span className="text-gray-700 text-sm font-medium font-gooper">
                  {`${testRun.latency}ms`}
                </span>
              </div>
              <div className="flex flex-row gap-2">
                <span className="text-gray-500 text-sm">{`Duration`}</span>
                <span className="text-gray-700 text-sm font-medium font-gooper">
                  {`${testRun.duration}ms`}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ParameterList: React.FC<{
  usedParameters: PortalTestParameters;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedTest: () => void;
}> = ({ usedParameters, isOpen, setIsOpen, selectedTest }) => {
  const isEmpty = Object.keys(usedParameters ?? {}).length == 0;
  return (
    <div
      className={`${
        isEmpty ? "hidden" : ""
      } flex flex-col gap-2 border-gray-200  rounded-lg ${
        isOpen ? "border" : "max-h-12"
      }`}
    >
      <div
        className={`flex border bg-blue-25 items-center flex-row gap-2 pt-1 pb-2 border-blue-50 justify-between ${
          isOpen ? "border-b rounded-t-lg" : "rounded-lg"
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center flex-row gap-2">
          <div
            className={`transition-all text-gray-700 duration-200 pl-3 font-medium font-gooper text-sm `}
          >
            Test Variables
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              selectedTest();
            }}
            className={`text-blue-400 cursor-pointer hover:text-blue-700 underline text-xs pr-2 transition-all duration-200`}
          >
            View test
          </div>
        </div>
        <Icon
          type="chevron"
          className={`size-7 text-blue-600 transition-all duration-200 ${
            isOpen ? "" : "rotate-180"
          }`}
        />
      </div>
      {isEmpty && (
        <div className="text-xs w-full text-gray-400">
          When you add variables to your prompt they'll appear here
        </div>
      )}
      <div
        className={`grid grid-cols-3 gap-2 max-h-44 px-2 ${
          isOpen ? "overflow-y-visible" : "h-0 overflow-clip"
        }`}
      >
        {Object.keys(usedParameters ?? {})
          .sort((a, b) => a.localeCompare(b))
          .map((variable) => {
            return (
              <div className="w-full" key={variable}>
                <UsedParameter
                  value={usedParameters[variable] || ""} // Provide a default empty string
                  name={variable}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

const UsedParameter: React.FC<{
  name: string;
  value?: string;
}> = ({ name, value }) => {
  return (
    <div className={`flex flex-col gap-0`}>
      <div className={`text-xs text-gray-500`}>{name}</div>
      <div className={`text-lg font-gooper text-gray-900`}>{value}</div>
    </div>
  );
};
