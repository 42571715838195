import React, { ReactNode, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavigationLink from "./NavigationLink";
import TeamSelector from "./TeamSelector";
import { Team } from "../../models/Team";
import NavigationUpsellButton from "./NavigationUpsellButton";
import NavigationFooter from "./NavigationFooter";
import NavigationHeader from "./NavigationHeader";
import { STORAGE_KEYS } from "../../utils/StorageKeys";
import { TeamAlert } from "../../models/TeamAlert";
import NotificationsLink from "./NotificationsLink";
import { User } from "../../models/User";
import { TeamSubscription } from "../../models/TeamSubscription";
import { NavigationResubButton } from "./NavigationResubButton";
import { usePortalService } from "../../contexts/PortalContext";
import { useWorkflowService } from "../../contexts/WorkflowContext";
import { AppPath } from "../../models/AppPath";
import { CommonErrorToast } from "../Common/CommonErrorToast";

interface VerticalNavLayoutProps {
  children: ReactNode;
  team: Team | undefined;
  alerts: TeamAlert[];
  currentUser: User | undefined;
  teamSubscription: TeamSubscription | undefined;
}

const VerticalNavLayout: React.FC<VerticalNavLayoutProps> = ({
  children,
  team,
  alerts,
  currentUser,
  teamSubscription,
}) => {
  const location = useLocation();

  const portalService = usePortalService();
  const workflowService = useWorkflowService();
  const navigate = useNavigate();

  const [portalError, setPortalError] = useState<string | null>(null);
  const [workflowError, setWorkflowError] = useState<string | null>(null);

  const [isCollapsed, setIsCollapsed] = useState(
    localStorage.getItem(STORAGE_KEYS.SIDEBAR_COLLAPSED) === "true"
  );

  const setSaveCollapsed = (isCollapsed: boolean) => {
    localStorage.setItem(
      STORAGE_KEYS.SIDEBAR_COLLAPSED,
      isCollapsed ? "true" : "false"
    );
    setIsCollapsed(isCollapsed);
  };

  const isActive = (path: string) => {
    return location.pathname.includes(path);
  };

  return (
    <div className="flex h-[calc(100dvh)] overflow-y-hidden overflow-x-hidden overscroll-none">
      <div
        className={`${
          isCollapsed ? "w-16 px-0" : "w-48 px-2"
        } flex flex-col gap-y-4 pt-3 bg-gray-100 transition-all duration-75 border-r border-gray-200`}
        id="sidebar-wrapper"
      >
        <NavigationHeader isCollapsed={isCollapsed} />

        <TeamSelector
          isCollapsed={isCollapsed}
          currentTeam={team}
          currentUser={currentUser}
        />

        <div className="flex-grow pt-2">
          <div className="list-none">
            <NavigationLink
              isCollapsed={isCollapsed}
              teamId={team?.id!}
              icon="home"
              destination="home"
              title="Home"
              isActive={isActive("home")}
            />
            <div className="relative">
              <NavigationLink
                isCollapsed={isCollapsed}
                teamId={team?.id!}
                icon="portals"
                destination="portals"
                title="Portals"
                isActive={isActive("portals")}
                action={async () => {
                  setPortalError(null); // Clear any previous errors
                  try {
                    const result = await portalService.createPortal(team!.id!);
                    navigate(
                      AppPath.portalVersion(
                        team!.id!,
                        result.portal.id,
                        result.version.id!,
                        true
                      )
                    );
                  } catch (e) {
                    setPortalError(
                      e instanceof Error
                        ? e.message
                        : "Failed to create portal. Please try again."
                    );
                  }
                }}
              />
              {portalError && (
                <CommonErrorToast
                  message={portalError}
                  onClose={() => setPortalError(null)}
                />
              )}
            </div>
            <div className="relative">
              <NavigationLink
                isCollapsed={isCollapsed}
                teamId={team?.id!}
                icon="workflows"
                destination="workflows"
                title="Workflows"
                isActive={isActive("workflows")}
                action={async () => {
                  try {
                    const newWorkflow = await workflowService.createWorkflow(
                      team!.id!
                    );
                    navigate(AppPath.workflow(team!.id!, newWorkflow.id!));
                  } catch (e) {
                    setWorkflowError(
                      e instanceof Error
                        ? e.message
                        : "Failed to create workflow. Please try again."
                    );
                  }
                }}
              />
              {workflowError && (
                <CommonErrorToast
                  message={workflowError}
                  onClose={() => setWorkflowError(null)}
                />
              )}
            </div>

            <NavigationLink
              isCollapsed={isCollapsed}
              teamId={team?.id!}
              icon="logs"
              destination="logs"
              title="Portal Logs"
              isActive={isActive("logs")}
            />
            <NavigationLink
              isCollapsed={isCollapsed}
              teamId={team?.id!}
              icon="chart-pie"
              destination="analytics"
              title="Analytics"
              isActive={isActive("analytics")}
            />
            <NavigationLink
              isCollapsed={isCollapsed}
              teamId={team?.id!}
              icon="flask"
              destination="test-center"
              title="Test Center"
              isActive={isActive("test-center")}
            />
          </div>
        </div>
        <div className="w-full flex flex-col gap-y-2 ">
          <div>
            <NavigationLink
              isCollapsed={isCollapsed}
              teamId={team?.id!}
              icon="profile"
              destination="profile"
              title="Profile"
              isActive={isActive("/profile")}
            />
            <NotificationsLink
              isCollapsed={isCollapsed}
              title="Notifications"
              isActive={isActive("notifications")}
              alerts={alerts}
              teamId={team?.id!}
            />
            <NavigationLink
              id={"teamSettingsButton"}
              isCollapsed={isCollapsed}
              teamId={team?.id!}
              icon="settings"
              destination="settings"
              title="Settings"
              isActive={isActive("settings")}
            />
          </div>
          <div className={`px-2 hidden sm:block`}>
            {teamSubscription?.currentEvent?.willRenew == false ? (
              <NavigationResubButton
                teamId={team?.id ?? ""}
                isCollapsed={isCollapsed}
              />
            ) : (
              <NavigationUpsellButton
                isCollapsed={isCollapsed}
                teamId={team?.id ?? ""}
              />
            )}
          </div>

          <div className={`pb-4 pt-2 ${isCollapsed ? "px-0" : "px-1"}`}>
            <NavigationFooter
              isCollapsed={isCollapsed}
              toggleCollapse={() => setSaveCollapsed(!isCollapsed)}
            />
          </div>
        </div>
      </div>
      <div className="w-full" id="page-content-wrapper">
        <div className="">{children}</div>
      </div>
    </div>
  );
};

export default VerticalNavLayout;
