import React from "react";
import { ChatConfigurationTemplate } from "../../../../models/ChatConfigurationTemplate";
import { AIProviderLogo } from "./AiProviderLogo";
import { CommonUnderlineButton } from "../../../Common/CommonUnderlineButton";

export const AIProviderList: React.FC<{
  clickedProvider: (id: string) => void;
  selectedProvider: string;
  providers: ChatConfigurationTemplate[];
  showProviderConfig: (show: boolean) => void;
}> = ({ clickedProvider, selectedProvider, providers, showProviderConfig }) => {
  return (
    <div className="flex flex-col gap-2 justify-normal w-full ">
      <div className="flex flex-row justify-between items-center">
        <div className="font-gooper text-sm text-gray-500 font-medium">
          Provider Configuration
        </div>
        <CommonUnderlineButton
          title="Open Provider Settings"
          onClick={() => showProviderConfig(true)}
        />
      </div>

      <div className="flex flex-wrap items-center gap-2 justify-start w-full">
        {providers.map((provider) => {
          return (
            <button
              id={provider.id}
              key={provider.id}
              className={`bg-gray-100 flex items-center justify-center stroke-gray-0 fill-gray-0 h-8 w-20 p-2 text-xs font-medium leading-none rounded-md `}
              onClick={() => clickedProvider(provider.id)}
            >
              <AIProviderLogo
                providerId={provider.id}
                className={`p-[1px] ${
                  selectedProvider == provider.id
                    ? "stroke-blue-500 fill-blue-500"
                    : "fill-gray-400"
                }`}
              />
            </button>
          );
        })}
      </div>
      {/* {notConfigured ? (
        <p className="text-xs font-sans font-normal text-gray-500">
          This provider is demo-mode only.{" "}
          <a
            href={`/teams/${team.id!}/settings/providers`}
            className="text-blue-500 hover:underline"
            target="_blank"
          >
            Set up your keys for API access.
          </a>
        </p>
      ) : (
        <p className="text-xs font-sans font-normal text-green-500">
          AI provider configured.
        </p>
      )} */}
    </div>
  );
};
